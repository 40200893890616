.catalog-product-page_body {
    @include breakpoint(small only) {
        padding-bottom: 50px;
    }
}

.product__view {
    .product__view_top {
        @include breakpoint(medium) {
            position: sticky;
            position: -webkit-sticky;
            top: 20rem;
        }
        @include breakpoint(small only) {
            margin: 1rem 0 0;
        }
        h1 {
            color: color(base, Black);
            font-weight: 600;
            font-size: 2.4rem;
            line-height: 3rem;
            @include breakpoint(small only) {
                font-size: 22px;
                line-height: 26px;
                margin: 15px 0;
            }
        }
        .product__price {
            @include breakpoint(small only) {
                margin: 0 0 15px;
            }
            .price {
                font-weight: 700;
                font-size: 5rem;
                line-height: 7rem;
                color: color(base, Antracite);
                @include breakpoint(small only) {
                    font-size: 4.5rem;
                    line-height: 6rem;
                }
                .price__old-price {
                    font-size: 16px;
                    text-decoration: line-through;
                    display: inline-block;
                    margin-right: 6px;
                    color: color(gray, Gray-200);
                }
            }
            .price__old-price {
                text-decoration: line-through;
                display: block;
                margin: 1rem 0 0;
                color: color(gray, Gray-200);
            }
            .starting-price {
                margin: 1rem 0 0;
                display: block;
                ._label {
                    line-height: 8.5rem;
                }
            }
        }
        .product__family {
            display: flex;
            margin: 0 0 20px;
            a {
                display: block;
                border: 1px solid color(gray, Gray-200);
                padding: 9px;
                margin-right: 10px;
                &:hover, &:first-child {
                    border-color: color(base, Black);
                }
            }
        }
    }
    .product__usp-block {
        padding: 2rem 1rem;
        border: .1rem solid color(gray, Gray-100);
        ul {
            li {
                font-size: 1.4rem;
                font-weight: 400;
                line-height: 2.4rem;
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='h-6 w-6' fill='none' viewBox='0 0 24 24' stroke='#{color(base, Green)}' stroke-width='2'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z' /%3E%3C/svg%3E");
                background-repeat: no-repeat;
                background-size: 1.6rem;
                padding-left: 2rem;
                background-position: left center;
                a {
                    font-size: 1.4rem;
                    font-weight: 600;
                    line-height: 2.4rem;
                }
            }
        }
    }
    .product__configuration {
        .product__options {
            margin: 1rem 0;

            .product__configurator {
                position: relative;
                z-index: 1;
                ._loading {
                    position: static;
                    ._spinner {
                        margin: 0;
                    }
                }
                .product__configurator-options {
                    margin: 0 0 15px;
                    padding: 2rem 0;
                    position: relative;

                    ._loading {
                        z-index: 99999;
                    }
                    .config__option {
                        margin: 0 0 20px;
                        position: relative;
                        z-index: 1;
                        &:last-of-type {
                            margin: 0;
                        }
                        > label {
                            font-size: 13px;
                            margin: 0 0 5px;
                            font-weight: 600;
                            color: color(gray, Gray-200);
                        }
                        .config__option-dropdown {
                            dt {
                                border: 1px solid color(gray, Gray-300);
                                height: 40px;
                                line-height: 40px;
                                font-size: 14px;
                                padding: 0 40px 0 15px;
                                border-radius: 4px;
                                color: color(gray, Gray-200);
                                background-color: color(base, White);
                                background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='14px' height='7px' viewBox='0 0 14 7' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3EPath 2%3C/title%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cpolygon id='Path-2' fill='%23909090' points='14 0 9.27044574 0 0 0 7 7'%3E%3C/polygon%3E%3C/g%3E%3C/svg%3E");
                                background-repeat: no-repeat;
                                background-position: right 20px center;
                                background-size: unset;
                                cursor: pointer;
                                font-weight: 500;
                                white-space: nowrap;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                &:hover {
                                    border-color: color(gray, Gray-200);
                                }
                                @include breakpoint(small only) {
                                    height: 32px;
                                    line-height: 32px;
                                    font-size: 12px;
                                }
                            }
                            dd {
                                display: none;
                                position: absolute;
                                background-color: color(base, White);
                                border: 1px solid color(gray, Gray-200);
                                border-top: none;
                                left: 0;
                                right: 0;
                                top: calc(100% - 1px);
                                border-radius: 0 0 4px 4px;
                                ._scrollable {
                                    max-height: 300px;
                                    overflow-y: auto;
                                    ol {
                                        display: flex;
                                        flex-wrap: wrap;
                                        li {
                                            flex: 100%;
                                            &._images {
                                                flex: 50%;
                                                text-align: center;
                                            }
                                            label {
                                                display: block;
                                                line-height: 35px;
                                                padding: 8px 15px;
                                                border-top: 1px solid color(gray, Gray-200);
                                                cursor: pointer;
                                                font-size: 14px;
                                                color: color(base, Black);
                                                ._image {
                                                    display: block;
                                                    padding: 0 10px 15px;
                                                    img {
                                                        margin: 0 auto;
                                                    }
                                                }
                                                ._value {
                                                    display: block;
                                                    line-height: 20px;
                                                }
                                                &:hover, &._active {
                                                    background: #db003205;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                            &._visible {
                                dt {
                                    border-radius: 4px 4px 0 0;
                                    border-color: color(gray, Gray-200);
                                    background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='14px' height='7px' viewBox='0 0 14 7' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3EPath 2%3C/title%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cpolygon id='Path-2' fill='%23909090' transform='translate(7.000000, 3.500000) rotate(-180.000000) translate(-7.000000, -3.500000) ' points='14 -1.13686838e-13 9.27044574 -1.13686838e-13 2.27373675e-13 -1.13686838e-13 7 7'%3E%3C/polygon%3E%3C/g%3E%3C/svg%3E");
                                }
                                dd {
                                    display: block;
                                }
                            }
                        }
                    }
                }
            }
        }
        .product__add-to-cart-container {
            margin: 15px 0;
            display: flex;
            align-items: center;
            max-width: 100%;
            position: relative;
            flex-wrap: wrap;
            row-gap: 1rem;
            gap: 1rem;
            @include breakpoint(large) {
                max-width: 100%;
                margin: 15px 0 30px;
            }
            .product__add-to-cart {
                position: fixed;
                background: color(base, White);
                border-top: .1rem solid color(gray, Gray-100);
                padding: 1rem .8rem 1rem;
                bottom: 0;
                left: 0;
                right: 0;
                display: flex;
                flex-flow: row wrap;
                z-index: 10;
            }
            .product-qty__holder {
                width: 7rem;
                @include breakpoint(small only) {
                    width: 7rem;
                }
                @include breakpoint(large) {
                    width: 8rem;
                }
                select {
                    height: 4rem;
                    line-height: 4rem;
                    background-position: right -1rem top 1.5rem;
                    padding: 0 2.4rem;
                    @include breakpoint(small only) {
                        padding: 0 1.5rem;
                    }
                }
            }
            .add-to-cart__holder {
                button {
                    line-height: 40px;
                    height: 40px;
                    width: 100%;
                    border-radius: 3px;
                    font-weight: 600;
                    font-size: 16px;
                    padding: 0 1rem;
                    @include breakpoint(small only) {
                        font-size: 14px;
                        padding: 0 2rem;
                    }
                    @include breakpoint(large) {
                        padding: 0 5rem;
                    }
                    &._cart-loading {
                        background-image: url("data:image/svg+xml,%3Csvg version='1.1' id='loader-1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='40px' height='40px' viewBox='0 0 50 50' style='enable-background:new 0 0 50 50;' xml:space='preserve'%3E%3Cpath fill='#{color(gray, Gray-200)}' d='M25.251,6.461c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615V6.461z'%3E%3CanimateTransform attributeType='xml' attributeName='transform' type='rotate' from='0 25 25' to='360 25 25' dur='0.6s' repeatCount='indefinite'/%3E%3C/path%3E%3C/svg%3E");
                        background-size: 15px;
                        background-repeat: no-repeat;
                        background-position: left 20px center;
                    }
                }
            }
            .add-to-favorite__holder {
                position: relative;
                width: 100%;
                margin: 1rem 0 0;
                > span {
                    @include transition;
                    cursor: pointer;
                    background-image: url("data:image/svg+xml,%0A%3Csvg width='27px' height='22px' viewBox='0 0 27 22' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='Hartje' transform='translate(0.828497, 0.955744)' fill='%23E1E8EB' fill-rule='nonzero'%3E%3Cg id='Group' transform='translate(13.000000, 10.500000) scale(-1, 1) rotate(-180.000000) translate(-13.000000, -10.500000) translate(0.000000, 0.000000)'%3E%3Cpath d='M6.45559029,20.9748701 C5.52790487,20.8600688 4.8439908,20.6777374 4.06189104,20.33671 C3.2053056,19.9652941 2.51800582,19.4892064 1.94243457,18.8780584 C0.869163481,17.7367986 0.293592234,16.6495629 0.0735208748,15.3361011 C-0.0178933821,14.7924833 -0.0246648085,13.9686152 0.0565923087,13.4182443 C0.280049381,11.9258276 1.01136344,10.3625043 2.08124881,9.07943106 C2.58572008,8.47503606 3.70300544,7.39117687 4.88123364,6.36134181 C6.59779024,4.86217208 9.69233212,2.41758001 12.2383885,0.543617851 L12.9798597,0 L13.1863882,0.138436844 C16.0236158,2.05629361 19.5718433,4.95671432 22.8492137,8.03608997 C24.569156,9.65343749 25.6153414,11.3788333 25.926827,13.1143586 C26.0080841,13.5836933 26.0250127,14.4210673 25.9606841,14.903908 C25.8252556,15.913484 25.4494414,16.8791654 24.8400131,17.7908227 C23.8852419,19.2123327 22.8390565,20.0328243 21.2951713,20.5561831 C18.7186436,21.4306988 16.3655729,21.0086352 14.3984735,19.327134 C13.9888022,18.9726006 13.5351166,18.4830069 13.2405596,18.0778259 C13.1389882,17.9393891 13.0475739,17.8279643 13.0374168,17.8245878 C13.0272596,17.8245878 12.8952168,17.9664011 12.7462454,18.1386031 C11.3411745,19.7491976 9.67878927,20.714879 7.90128983,20.9478581 C7.5593328,20.9917527 6.72306163,21.0086352 6.45559029,20.9748701 Z' id='Shape'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
                    background-repeat: no-repeat;
                    background-position: left center;
                    padding-left: 3.5rem;
                    display: block;
                    height: 22px;
                    font-weight: 500;
                    font-size: 1.4rem;
                    &:hover {
                        background-image: url("data:image/svg+xml,%0A%3Csvg width='27px' height='22px' viewBox='0 0 27 22' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='Hartje' transform='translate(0.828497, 0.955744)' fill='#{color(base, Green)}' fill-rule='nonzero'%3E%3Cg id='Group' transform='translate(13.000000, 10.500000) scale(-1, 1) rotate(-180.000000) translate(-13.000000, -10.500000) translate(0.000000, 0.000000)'%3E%3Cpath d='M6.45559029,20.9748701 C5.52790487,20.8600688 4.8439908,20.6777374 4.06189104,20.33671 C3.2053056,19.9652941 2.51800582,19.4892064 1.94243457,18.8780584 C0.869163481,17.7367986 0.293592234,16.6495629 0.0735208748,15.3361011 C-0.0178933821,14.7924833 -0.0246648085,13.9686152 0.0565923087,13.4182443 C0.280049381,11.9258276 1.01136344,10.3625043 2.08124881,9.07943106 C2.58572008,8.47503606 3.70300544,7.39117687 4.88123364,6.36134181 C6.59779024,4.86217208 9.69233212,2.41758001 12.2383885,0.543617851 L12.9798597,0 L13.1863882,0.138436844 C16.0236158,2.05629361 19.5718433,4.95671432 22.8492137,8.03608997 C24.569156,9.65343749 25.6153414,11.3788333 25.926827,13.1143586 C26.0080841,13.5836933 26.0250127,14.4210673 25.9606841,14.903908 C25.8252556,15.913484 25.4494414,16.8791654 24.8400131,17.7908227 C23.8852419,19.2123327 22.8390565,20.0328243 21.2951713,20.5561831 C18.7186436,21.4306988 16.3655729,21.0086352 14.3984735,19.327134 C13.9888022,18.9726006 13.5351166,18.4830069 13.2405596,18.0778259 C13.1389882,17.9393891 13.0475739,17.8279643 13.0374168,17.8245878 C13.0272596,17.8245878 12.8952168,17.9664011 12.7462454,18.1386031 C11.3411745,19.7491976 9.67878927,20.714879 7.90128983,20.9478581 C7.5593328,20.9917527 6.72306163,21.0086352 6.45559029,20.9748701 Z' id='Shape'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
                        text-decoration: underline;
                    }
                    &._active {
                        background-image: url("data:image/svg+xml,%0A%3Csvg width='27px' height='22px' viewBox='0 0 27 22' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='Hartje' transform='translate(0.828497, 0.955744)' fill='#{color(base, Green)}' fill-rule='nonzero'%3E%3Cg id='Group' transform='translate(13.000000, 10.500000) scale(-1, 1) rotate(-180.000000) translate(-13.000000, -10.500000) translate(0.000000, 0.000000)'%3E%3Cpath d='M6.45559029,20.9748701 C5.52790487,20.8600688 4.8439908,20.6777374 4.06189104,20.33671 C3.2053056,19.9652941 2.51800582,19.4892064 1.94243457,18.8780584 C0.869163481,17.7367986 0.293592234,16.6495629 0.0735208748,15.3361011 C-0.0178933821,14.7924833 -0.0246648085,13.9686152 0.0565923087,13.4182443 C0.280049381,11.9258276 1.01136344,10.3625043 2.08124881,9.07943106 C2.58572008,8.47503606 3.70300544,7.39117687 4.88123364,6.36134181 C6.59779024,4.86217208 9.69233212,2.41758001 12.2383885,0.543617851 L12.9798597,0 L13.1863882,0.138436844 C16.0236158,2.05629361 19.5718433,4.95671432 22.8492137,8.03608997 C24.569156,9.65343749 25.6153414,11.3788333 25.926827,13.1143586 C26.0080841,13.5836933 26.0250127,14.4210673 25.9606841,14.903908 C25.8252556,15.913484 25.4494414,16.8791654 24.8400131,17.7908227 C23.8852419,19.2123327 22.8390565,20.0328243 21.2951713,20.5561831 C18.7186436,21.4306988 16.3655729,21.0086352 14.3984735,19.327134 C13.9888022,18.9726006 13.5351166,18.4830069 13.2405596,18.0778259 C13.1389882,17.9393891 13.0475739,17.8279643 13.0374168,17.8245878 C13.0272596,17.8245878 12.8952168,17.9664011 12.7462454,18.1386031 C11.3411745,19.7491976 9.67878927,20.714879 7.90128983,20.9478581 C7.5593328,20.9917527 6.72306163,21.0086352 6.45559029,20.9748701 Z' id='Shape'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
                    }
                }
            }

            .--stock,
            .--delivery {
                position: relative;
                width: 100%;
                font-weight: 500;

                span {
                    font-size: 1.4rem;
                    display: flex;
                    align-items: center;
                    gap: .5rem;
                }

                svg {
                    width: 2rem;
                    display: inline-block;
                    vertical-align: top;
                }
            }

            .--stock {
                margin-top: 2rem;

                span {
                    color: #008000FF;

                    svg {
                        stroke: #008000FF;
                    }
                }
            }

            .added-to-cart {
                margin: 10px 0 0;
                display: block;
                font-size: 14px;
                font-weight: 500;
                color: color(base, Green);
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='icon icon-tabler icon-tabler-check' width='20' height='20' viewBox='0 0 24 24' stroke-width='3' stroke='#{color(base, Green)}' fill='none' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath stroke='none' d='M0 0h24v24H0z' fill='none'/%3E%3Cpath d='M5 12l5 5l10 -10' /%3E%3C/svg%3E");
                background-repeat: no-repeat;
                background-position: left center;
                padding-left: 28px;
                @include breakpoint(small only) {
                    font-size: 12px;
                    position: absolute;
                    top: -4rem;
                }
            }
        }
        .contact_form{
            font-size: 1.3rem;
            @include breakpoint(small only) {
                font-size: 1.4rem;
            }
            .__link{
                font-size: 1.3rem;
                @include breakpoint(small only) {
                    font-size: 1.4rem;
                }
                text-decoration: underline;
            }
        }
        .contact_form{
            gap: 1rem;
        }
        .add-to-cart__stock {
            span {
                color: color(alert, Neutral);
                line-height: 2.4rem;
                font-size: 1.6rem;
                margin: 1rem 0 0;
            }
        }
        .add-to-cart__shipping {
            font-weight: 600;
            line-height: 2.4rem;
            color: color(base, Green);
            margin: 1rem 0 0;
        }

        .add-to-cart__terms {
            a {
                color: color(alert, Neutral);
                text-decoration: underline;
            }
        }
    }
    .product__usp-list {
        padding: 25px;
        background-color: color(gray, Gray-200);
        li {
            line-height: 30px;
            color: color(base, Black);
            background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='15px' height='15px' viewBox='0 0 15 15' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3E003-draw-check-mark%3C/title%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='003-draw-check-mark' fill='#{color(base, Green)}' fill-rule='nonzero'%3E%3Cpath d='M6.02321739,14.5140652 C6.50647826,15.0031957 7.58282609,14.8410652 7.69871739,14.0719565 C8.41976087,9.29693478 12.0277174,5.26858696 14.7580435,1.47267391 C15.515087,0.420782609 13.7743696,-0.578478261 13.0271739,0.460891304 C10.5321522,3.92928261 7.38743478,7.57891304 6.15717391,11.7876522 C4.75128261,10.3508478 3.33971739,8.92767391 1.7628913,7.66513043 C0.764282609,6.86523913 -0.664173913,8.27445652 0.345195652,9.08276087 C2.3996087,10.7283261 4.17502174,12.6472174 6.02321739,14.5140652 Z' id='Path'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
            background-repeat: no-repeat;
            background-position: left center;
            padding-left: 25px;
            font-size: 14px;
            span {
                color: color(base, Green);
                font-size: 14px;
                font-weight: 500;
            }
        }
    }

    .product__contact {
        background-color: color(gray, Gray-200);
        padding: 20px 15px;
        margin: 0 0 15px;
        h3 {
            line-height: 22px;
            font-weight: 600;
        }
        p {
            line-height: 36px;
        }
        ul {
            display: flex;
            li:nth-child(1) {
                a {
                    background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='22px' height='15px' viewBox='0 0 22 15' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3E001-mail%3C/title%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='001-mail' fill='#{color(base, Antracite)}' fill-rule='nonzero'%3E%3Cpath d='M21.1454167,1.07170833 L14.6754583,7.5 L21.1454167,13.9282917 C21.262375,13.6838333 21.3333333,13.4135833 21.3333333,13.125 L21.3333333,1.875 C21.3333333,1.586375 21.262375,1.31616667 21.1454167,1.07170833 Z' id='Path'%3E%3C/path%3E%3Cpath d='M19.4583333,0 L1.875,0 C1.586375,0 1.31616667,0.0709583333 1.07170833,0.187916667 L9.341,8.41554167 C10.0721667,9.14670833 11.2611667,9.14670833 11.9923333,8.41554167 L20.261625,0.187916667 C20.0171667,0.0709583333 19.7469583,0 19.4583333,0 Z' id='Path'%3E%3C/path%3E%3Cpath d='M0.187916667,1.07170833 C0.0709583333,1.31616667 0,1.586375 0,1.875 L0,13.125 C0,13.413625 0.0709583333,13.683875 0.187916667,13.9282917 L6.657875,7.5 L0.187916667,1.07170833 Z' id='Path'%3E%3C/path%3E%3Cpath d='M13.7916667,8.38379167 L12.876125,9.29933333 C11.6578333,10.517625 9.67545833,10.517625 8.45716667,9.29933333 L7.54166667,8.38379167 L1.07170833,14.8120833 C1.31616667,14.9290417 1.586375,15 1.875,15 L19.4583333,15 C19.7469583,15 20.0171667,14.9290417 20.261625,14.8120833 L13.7916667,8.38379167 Z' id='Path'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
                    background-repeat: no-repeat;
                    background-position: left 0 center;
                    background-size: 15px;
                    padding-left: 20px;
                    line-height: 18px;
                    margin-right: 20px;
                    display: inline-block;
                    transition: all 0.2s;
                    &:hover {
                        background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='22px' height='15px' viewBox='0 0 22 15' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3E001-mail%3C/title%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='001-mail' fill='#{lighten(color(base, Antracite), 10%)}' fill-rule='nonzero'%3E%3Cpath d='M21.1454167,1.07170833 L14.6754583,7.5 L21.1454167,13.9282917 C21.262375,13.6838333 21.3333333,13.4135833 21.3333333,13.125 L21.3333333,1.875 C21.3333333,1.586375 21.262375,1.31616667 21.1454167,1.07170833 Z' id='Path'%3E%3C/path%3E%3Cpath d='M19.4583333,0 L1.875,0 C1.586375,0 1.31616667,0.0709583333 1.07170833,0.187916667 L9.341,8.41554167 C10.0721667,9.14670833 11.2611667,9.14670833 11.9923333,8.41554167 L20.261625,0.187916667 C20.0171667,0.0709583333 19.7469583,0 19.4583333,0 Z' id='Path'%3E%3C/path%3E%3Cpath d='M0.187916667,1.07170833 C0.0709583333,1.31616667 0,1.586375 0,1.875 L0,13.125 C0,13.413625 0.0709583333,13.683875 0.187916667,13.9282917 L6.657875,7.5 L0.187916667,1.07170833 Z' id='Path'%3E%3C/path%3E%3Cpath d='M13.7916667,8.38379167 L12.876125,9.29933333 C11.6578333,10.517625 9.67545833,10.517625 8.45716667,9.29933333 L7.54166667,8.38379167 L1.07170833,14.8120833 C1.31616667,14.9290417 1.586375,15 1.875,15 L19.4583333,15 C19.7469583,15 20.0171667,14.9290417 20.261625,14.8120833 L13.7916667,8.38379167 Z' id='Path'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
                    }
                }
            }
            li:nth-child(2) {
                a {
                    background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='15px' height='15px' viewBox='0 0 15 15' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3E002-call%3C/title%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='002-call' fill='#{color(base, Antracite)}' fill-rule='nonzero'%3E%3Cpath d='M13.7964062,9.84578125 C12.8780469,9.84578125 11.9763281,9.70214844 11.1218359,9.41976562 C10.703125,9.27695312 10.1883984,9.40796875 9.93285156,9.67042969 L8.24625,10.9436328 C6.29027344,9.89953125 5.08542969,8.69507812 4.05558594,6.75378906 L5.29132812,5.11113281 C5.61238281,4.79050781 5.72753906,4.32214844 5.58957031,3.88269531 C5.30597656,3.02371094 5.16191406,2.12242187 5.16191406,1.20367187 C5.16195312,0.539960938 4.62199219,0 3.95832031,0 L1.20363281,0 C0.539960937,0 0,0.539960938 0,1.20363281 C0,8.81105469 6.18898437,15 13.7964062,15 C14.4600781,15 15.0000391,14.4600391 15.0000391,13.7963672 L15.0000391,11.049375 C15.0000391,10.3857422 14.4600391,9.84578125 13.7964062,9.84578125 Z' id='Path'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
                    background-repeat: no-repeat;
                    background-position: left 0 center;
                    background-size: 15px;
                    padding-left: 20px;
                    line-height: 18px;
                    display: inline-block;
                    transition: all 0.2s;
                    &:hover {
                        background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='15px' height='15px' viewBox='0 0 15 15' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3E002-call%3C/title%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='002-call' fill='#{lighten(color(base, Antracite), 10%)}' fill-rule='nonzero'%3E%3Cpath d='M13.7964062,9.84578125 C12.8780469,9.84578125 11.9763281,9.70214844 11.1218359,9.41976562 C10.703125,9.27695312 10.1883984,9.40796875 9.93285156,9.67042969 L8.24625,10.9436328 C6.29027344,9.89953125 5.08542969,8.69507812 4.05558594,6.75378906 L5.29132812,5.11113281 C5.61238281,4.79050781 5.72753906,4.32214844 5.58957031,3.88269531 C5.30597656,3.02371094 5.16191406,2.12242187 5.16191406,1.20367187 C5.16195312,0.539960938 4.62199219,0 3.95832031,0 L1.20363281,0 C0.539960937,0 0,0.539960938 0,1.20363281 C0,8.81105469 6.18898437,15 13.7964062,15 C14.4600781,15 15.0000391,14.4600391 15.0000391,13.7963672 L15.0000391,11.049375 C15.0000391,10.3857422 14.4600391,9.84578125 13.7964062,9.84578125 Z' id='Path'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
                    }
                }
            }
        }
    }
    .product-info-container {
        background: color(gray, Gray-200);
        padding: 50px 0;
        margin: 60px 0;
        @include breakpoint(small only) {
            padding: 25px 0;
            margin: 30px 0;
        }
        h3 {
            font-size: 21px;
            line-height: 28px;
            margin: 0 0 30px;
            font-weight: 500;
            @include breakpoint(small only) {
                font-size: 17px;
                line-height: 25px;
                margin: 0 0 15px;
            }
        }
        p {
            font-size: 14px;
            line-height: 26px;
        }
    }
    .product__view-information {
        margin: 6rem 0 0;
        .product__specifications {
            @include breakpoint(small only) {
                margin: 2rem 0 0;
            }
            ul {
                li {
                    display: flex;
                    line-height: 4rem;
                    align-items: center;
                    height: 4rem;
                    font-size: 1.4rem;
                    strong {
                        width: 50%;
                        font-weight: 500;
                        padding-left: 2rem;
                    }
                    span {
                        width: 50%;
                    }
                    &:nth-child(2n) {
                        background: color(gray, Gray-100);
                    }
                }
            }
        }
    }
    .product__description, .product__specifications {
        h3 {
            font-size: 2.4rem;
            margin: 0 0 1rem;
            @include breakpoint(small only) {
                font-size: 2rem;
            }
        }
        a {
            font-weight: 500;
            text-decoration: underline;
        }
    }
    .product__related, .product__upsell {
        position: relative;
        margin: 7.5rem 0 0;
        @include breakpoint(small only) {
            margin: 3rem 0 0;
        }
        h2 {
            font-size: 2.4rem;
            margin: 0 0 1rem;
            @include breakpoint(small only) {
                font-size: 2rem;
            }
        }
        .slider__controls {
            position: absolute;
            right: 0;
            top: 1.5rem;
            @include breakpoint(small only) {
                display: none;
            }

            .swiper-button-next {
                right: 0;
                z-index: 1;

                &:after {
                    font-size: 1.6rem;
                    font-weight: 900;
                    color: color(gray, Gray-200);
                }
            }

            .swiper-button-prev {
                right: 4.5rem;
                left: auto;
                z-index: 1;
                @include breakpoint(small only) {
                    right: 4rem;
                }

                &:after {
                    font-size: 1.6rem;
                    font-weight: 900;
                    color: color(gray, Gray-200);
                }
            }
        }
        h3 {
            color: color(base, Black);
            font-size: 2.4rem;
            margin: 0 0 2rem;
            @include breakpoint(small only) {
                font-size: 2rem;
                margin: 0 0 1rem;
            }
        }
    }
}
